.menu-panels {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
  width: 1077px;
  height: 750px;

  .top {
    margin-bottom: 10px;
  }

  .justify {
    display: flex;
    justify-content: space-between;
  }

  .panel {
    cursor: pointer;
    filter: grayscale(100%);
    transition: filter 0.5s;

    &:hover {
      filter: grayscale(0%);
    }
  }
}
