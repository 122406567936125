.character-container {
  height: 100vh;
  box-sizing: border-box;
  position: relative;

  .character-list {
    width: 100%;
    height: calc(100% - 60px);
    padding-top: 60px;
    display: flex;

    .list-container {
      position: relative;
      margin: auto;
      left: 0;
      right: 0;
      width: 920px;
      height: 100%;
      padding: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.12) 0%,
        rgba(255, 255, 255, 0) 100%
      );

      .detail {
        width: 920px;
        min-height: 1000px;
        position: relative;

        .bg {
          position: absolute;
          opacity: 0.15;
          width: 920px;
          height: 700px;
          background: url("/static/img/main_white.png");
          background-repeat: no-repeat;
          background-size: 1700px;
          background-position: calc(50% + 200px) -350px;
          filter: blur(5px);
          mask-image: linear-gradient(
            180deg,
            #000 0%,
            #000 70%,
            transparent 100%
          );
        }

        .ui {
          animation: slide-from-bottom 0.3s ease-out;
          position: absolute;
          padding: 95px 0 0 50px;
          font-family: "Noto Serif KR";

          .title {
            font-size: 38pt;
            filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
            color: #eee;
            .big {
              font-size: 46pt;
            }
          }

          .desc {
            font-size: 16pt;
            color: #999;
            margin: -12px 0 0 3px;
          }
        }

        .character {
          &.show {
            .character-info {
              .title {
                animation: slide-from-left 0.4s ease-out;
              }

              .name {
                animation: slide-from-left 0.4s ease-out;
              }

              .name-eng {
                animation: slide-from-left 0.4s ease-out;
              }

              .emblem {
                animation: slide-emblem 0.4s ease-out;
              }

              .go-profile {
                animation: slide-from-bottom 0.4s ease-out;
              }
            }

            .image {
              img {
                animation: slide-from-right 0.5s ease-out;
              }
            }
          }

          .image {
            position: absolute;
            width: 100%;
            height: 720px;
            overflow: hidden;
            mask-image: linear-gradient(
              180deg,
              #000 0%,
              #000 60%,
              transparent 80%
            );

            .offset {
              position: absolute;
              top: 0;
              right: -290px;
            }

            img {
              position: relative;
              width: 1200px;
              height: 1200px;
              object-fit: scale-down;
              object-position: center top;
            }
          }

          .character-info {
            overflow: hidden;
            position: absolute;
            padding: 60px 0 0 50px;
            width: 500px;
            height: 500px;
            font-family: "Noto Serif KR";

            .title {
              margin-top: 10px;
              font-size: 16pt;
              color: #aaa;
            }

            .name {
              word-break: keep-all;
              font-size: 36pt;
              filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
              color: #ddd;
              line-height: 53px;
            }

            .name-eng {
              font-size: 14pt;
              color: #888;
            }

            .emblem {
              margin-top: 15px;
              margin-left: -12px;
              width: 100px;
              height: 100px;
              background-size: 110px;
              background-position: center center;
              opacity: 0.4;

              &.tank {
                background-image: url("/static/img/tank_white_min.png");
              }

              &.dps {
                background-image: url("/static/img/dps_white_min.png");
              }

              &.heal {
                background-image: url("/static/img/heal_white_min.png");
              }

              &.sup {
                background-image: url("/static/img/sup_white_min.png");
              }
            }

            .go-profile {
              position: absolute;
              bottom: 10px;
              button {
                width: 190px;
                height: 50px;
                border: none;
                background: none;
                background-image: url("/static/img/btn_character_profile.png");
                filter: drop-shadow(0 0 0px rgba(0, 0, 0, 0.5));
                transition: filter 0.2s;

                &:hover {
                  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
                }
              }
            }
          }
        }
      }

      .list {
        position: absolute;
        top: 530px;
        margin-left: 60px;
        width: 800px;
        padding-bottom: 50px;

        .filter {
          text-align: right;

          .filter-icon {
            border: none;
            background: none;
            width: 55px;
            height: 55px;
            padding: 0;
            background-size: 64px;
            background-position: -3px;
            opacity: 0.5;
            transition: opacity 0.2s;

            &:hover,
            &.selected {
              opacity: 1;
            }

            &.tank {
              background-image: url("/static/img/tank_white_filter.png");
            }

            &.dps {
              background-image: url("/static/img/dps_white_filter.png");
            }

            &.sup {
              background-image: url("/static/img/sup_white_filter.png");
            }

            &.heal {
              background-image: url("/static/img/heal_white_filter.png");
            }
          }
        }

        .items {
          width: 800px;
          height: 450px;
          overflow: hidden;

          .scroller {
            width: 830px;
            height: 450px;
            overflow-y: scroll;
          }
          .contents {
            margin-left: 25px;
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
            justify-content: left;
          }
          .item {
            cursor: pointer;
            width: 120px;
            height: 120px;
            overflow: hidden;
            border: 1px solid rgba(255, 255, 255, 0.5);
            filter: brightness(50%);
            transition: filter 0.2s;

            img {
              width: 118px;
              height: 118px;
            }

            &:hover,
            &.selected {
              filter: brightness(100%);
            }
          }
        }
      }
    }

    .arrow-btn {
      cursor: pointer;
      position: absolute;
      padding: 10px;
      opacity: 0.6;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
      }
    }

    .left {
      position: relative;
      flex: 1;

      .arrow-btn {
        top: 340px;
        right: 10px;
      }
    }

    .right {
      flex: 1;
      position: relative;

      .arrow-btn {
        top: 340px;
        left: 10px;
      }
    }
  }

  .character-view {
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    margin: auto;

    height: calc(100% - 60px);
    min-height: 900px;
    min-width: 1680px;
    max-width: 1920px;

    .class-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      mask-image: linear-gradient(270deg, #000 50%, transparent 100%);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: calc(50% - 300px) center;
      opacity: 0.1;
      transform: translate3d(0, 0, 0);
      transition: transform 0.8s cubic-bezier(0.25, 1, 0.5, 1);
    }

    &.tank .class-bg {
      background-image: url("/static/img/tank_white.png");
    }

    &.dps .class-bg {
      background-image: url("/static/img/dps_white.png");
    }

    &.heal .class-bg {
      background-image: url("/static/img/heal_white.png");
    }

    &.sup .class-bg {
      background-image: url("/static/img/sup_white.png");
    }

    .character-img {
      height: 100%;
      min-height: 840px;
      position: relative;
      width: 100%;
      transform: translate3d(0, 0, 0);
      transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);

      img {
        max-height: 100%;
        height: 1200px;
        width: 1200px;
        position: absolute;
        margin: auto;
        object-fit: scale-down;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }

    .informations {
      position: absolute;
      top: 0;
      left: 0;
      font-family: "Noto Serif KR";
      height: 100%;
      padding: 40px 0 0 30px;
      max-width: 700px;

      .title {
        margin-top: 25px;
        font-size: 17pt;
      }

      .name-box {
        margin-top: 20px;
        .name {
          color: #fff;
          font-size: 33pt;
          float: left;
          height: 55px;
          margin-top: -26px;
        }

        .eng {
          color: #ccc;
          padding-left: 5px;
          font-size: 15pt;
          height: 30px;
          float: left;
        }

        .clear {
          clear: both;
        }
      }

      .personal-info {
        margin-top: 10px;
        font-size: 13pt;
      }

      .skill-slots {
        transform: scale(0.8);
        transform-origin: left;
        margin-top: 10px;
      }

      .status {
        width: 348px;
        height: 402px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        background: url("/static/img/status.png");
        font-size: 10pt;

        .graph {
          position: absolute;
          top: 125px;
          left: 60px;
        }

        .stat {
          position: absolute;
          width: 40px;
          height: 20px;
          text-align: center;

          &:nth-of-type(1) {
            top: 107px;
            left: 145px;
          }

          &:nth-of-type(2) {
            top: 222px;
            left: 20px;
          }

          &:nth-of-type(3) {
            top: 222px;
            right: 42px;
          }

          &:nth-of-type(4) {
            bottom: 20px;
            left: 80px;
          }

          &:nth-of-type(5) {
            bottom: 20px;
            right: 100px;
          }
        }
      }
    }

    .menu-bar {
      transition: transform 0.8s cubic-bezier(0.25, 1, 0.5, 1);

      &.left {
        position: absolute;
        bottom: 0;
        left: 450px;
        width: 200px;
        height: 699px;
        font-family: "Noto Serif KR";
        background: url("/static/img/profile_left_menu_bar.png");

        .menu {
          background-image: url("/static/img/profile_menu_text.png");
          background-position: left;

          &:nth-of-type(1) {
            top: 210px;
            left: 30px;
          }

          &:nth-of-type(2) {
            top: 330px;
            left: 0px;
          }

          &:nth-of-type(3) {
            top: 450px;
            left: 5px;
          }

          .menu-icon {
            left: -25px;
            top: -18px;
          }

          .menu-text {
            margin-left: 50px;
          }
        }
      }

      &.right {
        position: absolute;
        top: -60px;
        right: 420px;
        width: 200px;
        height: 748px;
        font-family: "Noto Serif KR";
        background: url("/static/img/profile_right_menu_bar.png") right;

        .menu {
          background-image: url("/static/img/profile_menu_text_right.png");
          background-position: right;

          &:nth-of-type(1) {
            top: 250px;
            right: 5px;
          }

          &:nth-of-type(2) {
            top: 370px;
            right: 0px;
          }

          &:nth-of-type(3) {
            top: 490px;
            right: 30px;
          }

          .menu-icon {
            right: -25px;
            top: -18px;
          }

          .menu-text {
            text-align: right;
            margin-right: 50px;
          }
        }
      }

      .menu {
        width: 250px;
        height: 33px;
        background-repeat: no-repeat;
        position: absolute;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #fff;
        }

        .menu-icon {
          text-align: center;
          position: absolute;
          padding: 20px;
          width: 63px;
          height: 63px;
          background: url("/static/img/profile_menu_btn.png");
        }

        .menu-text {
          padding-top: 5px;
        }
      }
    }

    .quote {
      z-index: 50;
      position: absolute;
      text-align: right;
      right: 30px;
      top: 60%;
      opacity: 1;
      transition: opacity 0.3s;
      font-family: "Noto Serif KR";

      .quote-icon {
        margin-bottom: 10px;
      }

      .line {
        font-size: 18pt;
        color: #eee;
        display: inline-block;
        background: #000;
        padding: 2px 5px;
      }
    }

    &.open {
      .info-popup {
        transform: translate3d(0, 0, 0);
      }

      .class-bg {
        transform: translate3d(-250px, 0, 0);
      }

      .character-img {
        transform: translate3d(-100px, 0, 0);
      }

      .quote {
        opacity: 0;
      }

      .menu-bar.left {
        transform: translate3d(-80px, 0, 0);
      }

      .menu-bar.right {
        transform: translate3d(-200px, 0, 0);
      }
    }

    .badge {
      background: #aaa;
      font-size: 11pt;
      color: #000;
      border-radius: 11px;
      margin-right: 8px;
      width: 95px;
      padding: 5px;
    }
  }
}

@keyframes slide-from-right {
  from {
    opacity: 0;
    transform: translate(50px, 0);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0);
  }
}

@keyframes slide-from-left {
  from {
    opacity: 0;
    transform: translate(-30px, 0);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0);
  }
}
@keyframes slide-emblem {
  from {
    opacity: 0;
    transform: translate(-30px, 0);
  }

  to {
    opacity: 0.4;
    transform: translate(0px, 0);
  }
}

@keyframes slide-from-bottom {
  from {
    opacity: 0;
    transform: translate(0, 20px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
