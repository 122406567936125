@import url("./popup.scss");
@import url("./banner.scss");
@import url("./navigation.scss");

$color_tank: #a94734;
$color_dps: #b88f48;
$color_healer: #6c7cb0;
$color_support: #8c68b6;

.bg-tank {
  background-color: $color_tank !important;
}

.bg-dps {
  background-color: $color_dps !important;
}

.bg-heal {
  background-color: $color_healer !important;
}

.bg-sup {
  background-color: $color_support !important;
}

html,
body {
  overflow: visible;
  padding: 0;
  margin: 0;
  background: #000;
  color: #aaa;
  font-family: "Pretendard";
  font-weight: 400;
}

.video-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;

  .bg-video {
    object-fit: cover;
    z-index: 1;
    height: 100%;
    width: 100%;
  }

  .bg-image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url("/static/img/main_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transition: opacity 1s;
    z-index: 2;
  }
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: 2000;

  &.fadeout {
    animation: fadeout 0.3s;
    opacity: 0;
  }

  .spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 300px;
    height: 300px;
  }
}

.bgm {
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
}

a {
  text-decoration: none;
  color: #aaa;

  &:visited {
    color: #aaa;
  }

  &:hover {
    color: #fff;
  }
}

.page-title {
  font-family: "Noto Serif KR";
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  z-index: 100;
  display: flex;
  padding: 10px 30px;
  height: 60px;
  box-sizing: border-box;

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: calc(100% - 60px);
    bottom: -1px;
    height: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }

  .title-icon {
    display: block;
    width: 40px;
    height: 40px;
    background: url("/static/img/head_icon.png");
    background-size: auto;
    background-position: -11px -11px;
  }

  .title-text {
    color: rgba(255, 255, 255, 0.7);
    display: block;
    text-align: top;
    font-size: 16pt;
    margin: 5px 0 0 6px;
  }

  .title-links {
    position: absolute;
    right: 35px;
    top: 20px;

    .link {
      display: inline-block;
      padding-left: 20px;
    }
  }
}

.page-404 {
  width: 100vw;
  height: 100vh;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 14pt;
  position: relative;
  z-index: 10;

  a {
    text-decoration: underline;
  }
}

.lobby {
  width: 100vw;
  height: 100vh;
  .bg-emblem {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 11;
    top: calc(50% - 350px);
    width: 700px;
    height: 700px;
    opacity: 0.1;
    background-image: url("/static/img/main_white.png");
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .logo {
    position: absolute;
    top: calc(50% - 100px);
    left: 0;
    right: 0;
    z-index: 12;
    margin: auto;
    width: 600px;
    height: 200px;
    background-image: url("/static/img/main_logo.png");
    background-size: 100%;
    background-repeat: no-repeat;

    &.withbanner {
      top: calc(50% - 140px);
    }
  }

  .banner {
    font-family: "Noto Serif KR";
    position: absolute;
    z-index: 12;
    top: calc(50% + 15px);
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;

    .title {
      font-size: 24pt;
    }

    .date {
      margin-top: -5px;
    }

    .link-btn {
      margin-top: 8px;
      display: inline-block;
      width: 167px;
      height: 40px;
      background-image: url("/static/img/application_btn.png");
      transition: background-color 0.3s;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

.site-toast-container {
  position: fixed;
  top: 100px;
  left: calc(50% - 250px);
  z-index: 2100;
  width: 500px;
  opacity: 1;

  .site-toast {
    position: relative;
    display: block;
    overflow: auto;
    height: auto;
    margin-bottom: 8px;

    .box {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 10%,
        rgba(0, 0, 0, 0.6) 90%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    .text {
      position: relative;
      z-index: 100;
      text-align: center;
      padding: 15px;
      color: rgba(255, 255, 255, 0.8);
    }

    &.removed {
      visibility: hidden;
    }

    &.show {
      .box {
        animation: toast-box 0.3s;
      }

      .text {
        animation: toast-text 0.3s;
      }
    }

    &.hide {
      .box {
        transform: scaleY(0);
        animation: toast-box 0.3s reverse;
      }

      .text {
        opacity: 0;
        animation: toast-text 0.3s reverse;
      }
    }
  }

  @keyframes toast-box {
    0% {
      transform: scaleY(0);
    }

    100% {
      transform: scaleY(1);
    }
  }

  @keyframes toast-text {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
