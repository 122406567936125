.game-banner {
  z-index: 300;
  width: 840px;
  height: 550px;
  position: absolute;
  left: calc(50% - 420px);
  top: 100px;
  pointer-events: none;

  .drop-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 840px;
    height: 550px;
    background: url("/static/img/battle_banner_shadow.png");
  }

  .emblem {
    position: absolute;
    top: 38px;
    left: 158px;
    width: 524px;
    height: 354px;
    background: url("/static/img/battle_banner_emblem.png");
  }

  .box {
    position: absolute;
    top: 213px;
    left: 158px;
    width: 524px;
    height: 167px;
    background: url("/static/img/battle_banner_box.png");
    background-position: center center;
    overflow: hidden;
  }

  .text {
    position: absolute;
    top: 240px;
    left: 158px;
    text-align: center;
    width: 524px;
    color: #fff;
    font-family: "Noto Serif KR";
    text-shadow: 0px 0px 8px #000, 0px 0px 8px #000;

    .title {
      font-size: 42pt;
      height: 70px;
      overflow: hidden;

      span {
        display: inline-block;
      }
    }

    .desc {
      margin-top: 5px;
      font-size: 14pt;
      height: 24px;
      overflow: hidden;

      span {
        display: inline-block;
      }
    }

    .exit {
      margin-top: 8px;
      font-size: 14px;
      border: none;
      border-radius: 30px;
      padding: 3px 20px;
      background: #eee;
      cursor: pointer;
      pointer-events: all;

      &:hover {
        background: #fff;
      }
    }
  }

  .rewards {
    opacity: 0;
    position: absolute;
    width: 200px;
    top: 400px;
    left: calc(50% - 100px);
    text-align: center;
    text-shadow: 0px 0px 5px #000;
    animation: fadein 0.3s 0.3s forwards;
    color: rgba(255, 255, 255, 0.5);
    .reward-title {
      font-family: "Noto Serif KR";
      color: #fff;
      font-size: 14pt;
    }

    .reward {
      opacity: 0;
      &.s0 {
        animation: reward-fadein 0.5s 0.3s forwards;
      }
      &.s1 {
        animation: reward-fadein 0.8s 0.3s forwards;
      }
      &.s2 {
        animation: reward-fadein 1.1s 0.3s forwards;
      }
      &.s3 {
        animation: reward-fadein 1.4s 0.3s forwards;
      }
      &.s4 {
        animation: reward-fadein 1.7s 0.3s forwards;
      }
    }
  }

  &.show {
    .drop-shadow {
      animation: fadein 0.3s;
    }

    .emblem {
      animation: emblem-fadein 0.3s;
    }

    .box {
      animation: box-fadein 0.5s;
    }

    .title span {
      transform: translateY(100%);
      animation: text-fadein 0.3s 0.2s forwards;
    }

    .desc span {
      transform: translateY(100%);
      animation: text-fadein 0.3s 0.3s forwards;
    }
  }

  &.hide {
    .drop-shadow {
      animation: fadein 0.3s 0.2s forwards reverse;
    }

    .emblem {
      animation: emblem-fadein 0.3s 0.2s forwards reverse;
    }

    .box {
      animation: box-fadein 0.5s 0.2s forwards reverse;
    }

    .title span {
      animation: text-fadein 0.3s 0.1s forwards reverse;
    }

    .desc span {
      animation: text-fadein 0.3s forwards reverse;
    }
  }

  @keyframes emblem-fadein {
    0% {
      opacity: 0;
      transform: translateY(15px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @keyframes box-fadein {
    0% {
      transform: scaleX(0);
    }

    100% {
      transform: scaleX(1);
    }
  }

  @keyframes text-fadein {
    0% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(0);
    }
  }
  @keyframes reward-fadein {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
