$min-width: 1680px;
$min-height: 900px;

.game-lobby {
  .refresh {
    cursor: pointer;
  }
  position: relative;
  top: 60px;
  left: 0;
  right: 0;
  margin: auto;
  width: 1200px;
  padding: 30px 50px;

  h4 {
    font-family: "Noto Serif KR";
    font-size: 18pt;
    color: #eaeaea;
    margin-bottom: 10px;
    small {
      color: #ddd;
      margin-left: 10px;
      font-family: "Pretendard";
      font-weight: 200;
      font-size: 12pt;
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .badge {
      background: #ccc;
      color: #333;
      border-radius: 20px;
      padding: 5px 10px;
      margin-right: 10px;
    }
    svg {
      width: 20px;
    }

    .ap-number {
      margin-right: 5px;
    }
  }

  .list-preview {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;

    .list-container {
      width: 300px;
      .list {
        width: 300px;
        height: 500px;
        overflow: hidden;

        .list-scroller {
          overflow-y: scroll;
          height: 500px;
          width: 320px;
        }
        .scroll-contents {
          width: 300px;
        }

        .item {
          background: rgba(0, 0, 0, 0.2);
          cursor: pointer;
          padding: 15px;
          border: 1px solid rgba(255, 255, 255, 0.3);
          transition: border-color 0.3s;
          margin-bottom: 10px;
          color: #ddd;
          position: relative;
          overflow: hidden;

          .title {
            font-weight: bold;
            color: #eaeaea;
          }
          .date {
            font-size: 10pt;
          }

          .bg {
            position: absolute;
            z-index: -1;
            right: 0;
            top: 0;
            width: 300px;
            mask-image: linear-gradient(to left, #000 0%, transparent 50%);
          }

          &.selected,
          &:hover {
            border-color: #ddd;
          }
        }
      }
    }

    .preview-container {
      flex: 1;
      .preview-outline {
        border: 1px solid rgba(255, 255, 255, 0.3);
        width: 100%;
        height: 500px;
      }
      .no-selection {
        width: 100%;
        padding-top: 235px;
        text-align: center;
        font-size: 14pt;
      }
      .preview {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 50px;
        isolation: isolate;
        color: #ddd;
        background-size: auto 120%;
        background-position: top center;

        &::after {
          content: "";
          position: absolute;
          background: #000;
          z-index: -1;
          inset: 0;
          opacity: 0.6;
        }

        .title {
          color: #fff;
          font-family: "Noto Serif KR";
          font-size: 24pt;
          margin-bottom: 2px;
        }
        .date {
          font-size: 10pt;
          margin-bottom: 5px;
        }
        .desc {
          margin-bottom: 10px;
          word-break: break-all;
          white-space: pre-wrap;
        }
        .rewards {
          position: absolute;
          left: 50px;
          bottom: 50px;
          .reward-name {
            color: #fff;
            margin-bottom: 5px;
          }
          .reward {
            width: 26px;
            height: 26px;
            border: 1px solid #999;
            margin-right: 3px;
          }
        }

        .buttons {
          position: absolute;
          right: 50px;
          bottom: 50px;
          width: 500px;
          text-align: right;

          .button {
            margin-left: 10px;
            display: inline-block;
            cursor: pointer;
            font-weight: bold;
            background: #ddd;
            color: #333;
            text-align: center;
            font-family: "Noto Serif KR";
            border-radius: 40px;
            padding: 8px;
            width: 120px;
            transition: background 0.3s;

            &:hover {
              background: #fff;
            }

            &.large {
              width: 200px;
              font-size: 14pt;
              padding: 10px;
            }
          }
        }
      }
    }
  }

  .log-container {
    margin-bottom: 30px;
    min-height: 170px;

    .log-list {
      display: flex;
      gap: 10px 10px;
      flex-wrap: wrap;

      .log {
        background: rgba(0, 0, 0, 0.2);
        width: 360px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 20px;
        position: relative;

        .join {
          position: absolute;
          right: 20px;
          top: calc(50% - 20px);
          width: 40px;
          height: 40px;
          padding: 5px 7px;
          cursor: pointer;
          border-radius: 20px;
          border: 1px solid rgba(255, 255, 255, 0.3);
          transition: border 0.3s, color 0.3s;
          &:hover {
            color: #fff;
            border-color: #ddd;
          }
        }

        .title {
          color: #eaeaea;
          font-weight: bold;
        }
        .badge {
          border-radius: 20px;
          padding: 3px 15px;
          margin-bottom: 5px;
          background: #333;

          &.success {
            background: #00aa85;
          }
          &.fail {
            background: rgb(179, 10, 44);
          }
        }
        .time {
          font-size: 10pt;
        }
      }
    }
  }
}
