.skill-component {
  width: 100%;
  padding: 10px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;

  &.clickable {
    cursor: pointer;
    transition: border 0.3s;

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.4);
    }
  }

  .skill-img {
    width: 50px;

    .skill-icon {
      position: relative;

      .count {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 24pt;
        font-weight: bold;
        text-align: center;
      }

      img {
        width: 50px;
        height: 50px;
      }

      &.active::after {
        content: "장착중";
        top: -5px;
        right: -5px;
        font-size: 8pt;
        overflow: hidden;
        color: #fff;
        background: #000;
        width: 38px;
        padding: 2px 5px;
        position: absolute;
      }
    }
  }

  .skill-caption {
    padding-left: 15px;
    flex: 1;

    .name {
      font-weight: bold;
      font-size: 14pt;
    }

    .options {
      margin-top: -4px;
      font-size: 10pt;
    }
  }
}

.skill-slot {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));

  &::after {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 10;
    background: #e8e8e8;
    content: "";
    width: 40px;
    height: 40px;
    display: block;
    transform: rotate(45deg);
  }

  .lock {
    position: absolute;
    top: 20px;
    left: 23px;
    z-index: 20;
    color: #aaa;
  }

  .skill-icon {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 20;
    background: #e8e8e8;
    width: 46px;
    height: 46px;
    padding: 2px;

    img {
      display: block;
      width: 42px;
      height: 42px;
    }
  }
}
