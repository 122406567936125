.tools {
  z-index: 1000;
  position: fixed;
  right: 30px;
  top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;

  svg {
    cursor: pointer;
    color: #fff;
    opacity: 0.5;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
    }
  }
}
.nav-btn {
  cursor: pointer;
  width: 40px;
  padding: 10px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  position: fixed;
  bottom: 10px;
  left: calc(50% - 20px);
  transition: transform 0.3s, color 0.3s;

  &:hover {
    color: #fff;
  }

  &.hide {
    transform: rotate(180deg);
  }
}

.navigation {
  box-sizing: border-box;
  display: flex;
  width: 300px;
  height: 50px;
  z-index: 1000;
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 1;
  transition: transform 0.3s, opacity 0.3s;
  justify-content: space-between;
  filter: drop-shadow(0 0 10px #000);

  &.close {
    pointer-events: none;
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }

  .nav-item {
    width: 50px;

    &:hover,
    .active {
      img {
        opacity: 1;
      }
    }

    img {
      opacity: 0.5;
      transition: opacity 0.3s;
    }
  }
}
