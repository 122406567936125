.spinner-component {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  animation: spinner-fadein 0.3s;

  &.fadeout {
    animation: spinner-fadeout 0.3s;
    opacity: 0;
  }

  .spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 200px;
    height: 200px;
    margin: auto;
  }

  .progress-bar {
    position: absolute;
    top: calc(50% + 100px);
    left: 0;
    right: 0;
    margin: auto;
    width: 200px;
    height: 3px;
    border-radius: 5px;
    overflow: hidden;

    .bar {
      position: relative;
      left: 0;
      height: 3px;
      border-radius: 5px;
      background: #fff;
      transition: width 0.1s;
    }
  }
}

.spinner-contents {
  animation: spinner-fadein 0.3s;
}

@keyframes spinner-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spinner-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
