.standby-container {
  width: 1100px;
  height: 800px;
  top: 0;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  background: #111;
  border: 1px solid #333;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-size: cover;
    background-position: center center;
    mask-image: linear-gradient(to bottom, #fff 0%, transparent 100%);
  }

  .contents {
    position: relative;
    padding: 50px;
    height: 800px;
    display: flex;
    justify-content: space-between;

    .badge {
      background: #aaa;
      font-size: 10pt;
      border-radius: 20px;
      color: #333;
      padding: 5px 25px;
      font-weight: 500;
    }

    .standby-left {
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-size: 26pt;
        color: #ddd;
        font-family: "Noto Serif KR";
      }
      .leader {
        margin-top: 10px;
        .badge {
          width: 100px;
        }
        .name {
          display: inline-block;
          margin-left: 5px;
          padding-top: 5px;
          line-height: 10px;
          color: #ddd;
        }
      }
      .chat-list {
        mask-image: linear-gradient(to top, #fff 90%, transparent 100%);

        .chat-container {
          width: 400px;
          height: 500px;
          overflow: hidden;
        }

        .scroller {
          width: 430px;
          height: 500px;
          overflow-y: scroll;
          overflow-x: hidden;

          ul {
            width: 400px;
            margin: 0;
            padding: 0;
            padding-top: 30px;
          }
          li {
            padding: 0;
            margin: 0;
            list-style: none;
          }

          li {
            margin-bottom: 5px;
            display: flex;
          }

          .name {
            width: 100px;
            height: 24px;
            background: rgba(0, 0, 0, 0.7);
            border-radius: 20px;
            color: #fff;
            font-size: 12pt;
            text-align: center;
          }

          .text {
            -webkit-user-select: text;
            -moz-user-select: text;
            -ms-use-select: text;
            user-select: text;
            word-break: break-all;
            color: #eee;
            font-size: 12pt;
            padding-left: 10px;
            width: 230px;
          }
        }

        .chat-input {
          border-radius: 10px;
          height: 30px;
          background: rgba(0, 0, 0, 0.5);
          position: relative;

          ::before {
            margin-left: 4px;
            content: "";
            display: inline-block;
            background-image: url("/static/img/chat_icon.png");
            background-position: center bottom;
            width: 26px;
            height: 28px;
          }

          input {
            position: absolute;
            top: 2px;
            left: 35px;
            width: 350px;
            background: none;
            border: none;
            color: #fff;
          }

          button {
            display: none;
          }
        }
      }
    }

    .standby-right {
      width: 560px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .player-list {
        .player {
          position: relative;
          background: #bbb;
          margin-bottom: 14px;
          height: 150px;
          border-radius: 25px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
          .info {
            padding: 20px;

            .badge {
              background: #333;
              color: #ddd;
              width: 100px;

              &.ready {
                background: #5c8143;
              }
            }

            .name {
              img {
                margin-right: 5px;
              }
              font-size: 18pt;
              color: #333;
              font-weight: 600;
              height: 40px;
            }

            .skills {
              transform-origin: left top;
              transform: scale(0.8);

              .skill-slot {
                filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.3));
              }
            }
          }

          .portrait {
            position: absolute;
            right: 0px;
            bottom: 0;
            height: 150px;
            .chat-bubble {
              bottom: 130px;
            }
            .img {
              width: 220px;
              height: 150px;
              object-fit: cover;
              object-position: left;
            }
          }
        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;

        .ready {
          margin-right: 10px;
        }
        .exit {
          border-color: #777;
        }
        button {
          img {
            margin-right: 6px;
            margin-top: -3px;
            transition: filter 0.3s;
          }
          font-size: 14pt;
          font-weight: 600;
          background: rgba(0, 0, 0, 0.3);
          border: 1px solid #aaa;
          color: #aaa;
          border-radius: 40px;
          padding: 6px 30px 6px 15px;

          transition: color 0.3s, background 0.3s;

          &.active {
            background: #ddd;
            color: #333;
            img {
              filter: brightness(50%);
            }
          }
        }
      }
    }
  }
}
