@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes drop {
  0% {
    transform: translate3d(0, 0, 30px);
  }

  100% {
    transform: translate3d(0, 0, 0px);
  }
}

@keyframes move-bottom {
  0% {
    background-position: -70px 0px;
  }

  25% {
    background-position: -140px 0px;
  }

  50% {
    background-position: -70px 0px;
  }

  75% {
    background-position: 0px 0px;
  }
}

@keyframes move-top {
  0% {
    background-position: -70px -320px;
  }

  25% {
    background-position: -140px -320px;
  }

  50% {
    background-position: -70px -320px;
  }

  75% {
    background-position: 0px -320px;
  }
}

@keyframes move-right {
  0% {
    background-position: -70px -215px;
  }

  25% {
    background-position: -140px -215px;
  }

  50% {
    background-position: -70px -215px;
  }

  75% {
    background-position: 0px -215px;
  }
}

@keyframes move-left {
  0% {
    background-position: -70px -110px;
  }

  25% {
    background-position: -140px -110px;
  }

  50% {
    background-position: -70px -110px;
  }

  75% {
    background-position: 0px -110px;
  }
}

@mixin cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.explore-window {
  position: relative;
  width: 100vw;
  height: 100vh;
  min-width: 1680px;
  min-height: 900px;
  overflow: hidden;

  .background {
    @include cover;
    z-index: 9;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .pattern {
      @include cover;
      z-index: 10;
      background: url("/static/img/battle_pattern.png");
      background-size: auto 100%;
      background-repeat: repeat-x;
    }
  }

  .explore-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    min-width: 1680px;
    min-height: 900px;
  }

  .info {
    position: absolute;
    top: 120px;
    left: 40px;
    z-index: 100;

    .character {
      width: 350px;
      margin: 0 25px;
      height: 70px;
      background: url("/static/img/info_gage.png");
      background-repeat: no-repeat;
      background-position: right top;
      position: relative;
      z-index: 130;

      .hp {
        width: 280px;
        position: absolute;
        top: 5px;
        left: 65px;

        .hp-bar {
          height: 22px;
          background: url("/static/img/hp_bar.png");
          background-repeat: no-repeat;
          transition: width 0.3s;
        }

        .sp-bar {
          position: absolute;
          left: 28px;
          top: 4px;

          .sp-dot {
            float: left;
            width: 12px;
            height: 12px;
            border: 2px solid rgba(255, 255, 255, 0.8);
            border-radius: 24px;
            margin-right: 5px;

            .fill {
              margin-left: 1px;
              margin-top: 1px;
              content: "";
              display: block;
              background: #fff;
              width: 6px;
              height: 6px;
              border-radius: 10px;

              &.use {
                animation: dot-blink 1s linear infinite alternate;
              }

              @keyframes dot-blink {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
            }
          }
        }

        .hp-text {
          position: absolute;
          right: 7px;
          top: -1px;
          color: #999;

          strong {
            color: #fff;
            font-weight: 400;
          }
        }
      }

      .name {
        font-family: "Noto Serif KR";
        color: #fff;
        font-size: 16pt;
        position: absolute;
        bottom: 7px;
        left: 90px;
      }

      .buffs {
        width: 350px;
        text-align: right;
        position: absolute;
        right: 0px;
        top: -30px;
      }

      .emblem {
        position: absolute;
        top: -30px;
        left: -10px;
        width: 100px;
        height: 100px;
        background: url("/static/img/explore_circle.png");
        background-size: 100px;

        .emblem-img {
          border-radius: 100px;
          margin-top: 6px;
          margin-left: 6px;
          width: 89px;
          height: 89px;
          background-size: 90px;
          background-position: center center;
        }

        .emblem-overlay {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100px;
          height: 100px;
          background: url("/static/img/explore_circle_shadow.png");
          background-size: 100px;
        }
      }
    }

    .quests {
      margin: 25px 0 0 25px;

      .tab-title {
        color: #fff;
        background: url("/static/img/explore_quest.png");
        width: 327px;
        height: 29px;
        cursor: pointer;

        svg {
          margin-left: 3px;
        }

        .title-text {
          margin-left: 9px;
          font-family: "Noto Serif KR";
        }
      }

      .tab {
        margin-top: 10px;
        margin-left: 10px;

        .item {
          margin-bottom: 5px;
          text-shadow: 0px 0px 3px #000;
          color: #fff;
          padding-left: 25px;
          background-image: url("/static/img/quest_head.png");
          background-repeat: no-repeat;
          background-position: 0px 3px;

          &.complete {
            background-image: url("/static/img/quest_head_check.png");
          }
        }
      }
    }
  }

  .menu {
    position: absolute;
    top: 100px;
    right: 50px;
    z-index: 120;

    .ap {
      width: 115px;
      height: 115px;
      text-align: center;
      padding: 40px 20px;
      background-image: url("/static/img/ap_icon.png");

      .cur {
        color: #fff;
      }

      .max {
        color: #999;
      }
    }

    .menu-icon {
      margin: 10px 29px;
      padding: 17px;
      color: #fff;
      cursor: pointer;
      background: url("/static/img/menu_icon.png");
      width: 60px;
      height: 60px;
    }
  }

  .explore-inventory {
    padding: 10px;
    height: 435px;
    overflow: hidden;
    .scroller {
      overflow-y: scroll;
      overflow-x: hidden;
      width: 410px;
      height: 435px;
    }
    .scroll-contents {
      width: 380px;
    }
  }

  .explore-stage {
    @include cover;
    z-index: 99;
    perspective: 500px;

    .encounter {
      position: absolute;
      // top: -20px;
      filter: drop-shadow(0 0 5px #000);
      top: 0;
      bottom: 210px;
      right: 0;
      left: 0;
      margin: auto;
      z-index: 100;
      width: 40px;
      height: 40px;
      background-image: url("/static/img/emo_encounter.png");
      animation: encounter 0.3s;

      &.trap {
        background-image: url("/static/img/emo_trap.png");
      }

      @keyframes encounter {
        0% {
          transform: translateY(10px);
          opacity: 0;
        }

        100% {
          transform: translateY(0px);
          opacity: 1;
        }
      }
    }

    .perspective {
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;
      transform: rotateX(-30deg);
    }

    .blocks-perspective {
      width: 100%;
      height: 100%;
      transform: rotateX(90deg);
      transform-style: preserve-3d;
      position: relative;

      .blocks {
        transform-style: preserve-3d;
        overflow: visible;

        .block {
          transform-style: preserve-3d;
          width: 150px;
          height: 150px;
          position: absolute;
          border: 1px solid #000;
          transition: border 0.3s, background 0.8s;
          overflow: hidden;
          background: rgba(0, 0, 0, 0.3);
          animation: fadein 0.5s, drop 0.5s;

          &.visit {
            background: rgba(0, 0, 0, 0.6);
          }

          &.active {
            &:hover {
              cursor: pointer;
              border: 10px solid rgb(117, 255, 158);
            }

            &.start:hover {
              border: 10px solid rgb(255, 255, 255);
            }
          }
        }

        .effect {
          position: absolute;
          transform-style: preserve-3d;
          width: 150px;
          height: 150px;
          pointer-events: none;
          animation: drop 0.5s;
          opacity: 1;

          &.start {
            div {
              background: linear-gradient(
                transparent,
                rgba(255, 255, 255, 0.8)
              );
            }
          }

          &.end {
            div {
              background: linear-gradient(
                transparent,
                rgba(117, 255, 158, 0.8)
              );
            }
          }

          div {
            animation: fadein 0.5s;
            position: absolute;
            width: 150px;
            height: 50px;
          }

          .top {
            top: -25px;
            transform: translate3D(0, 0px, 25px) rotateX(-90deg);
          }

          .bottom {
            bottom: -25px;
            transform: translate3D(0, 0px, 25px) rotateX(-90deg);
          }

          .left {
            left: -50%;
            bottom: 50px;
            transform: translate3D(0, 0px, 25px) rotateZ(90deg) rotateX(-90deg);
          }

          .right {
            right: -50%;
            bottom: 50px;
            transform: translate3D(0, 0px, 25px) rotateZ(90deg) rotateX(-90deg);
          }
        }

        .character {
          transform-style: preserve-3d;
          transition: transform 0.5s;
          position: absolute;
          pointer-events: none;

          .sprite {
            width: 70px;
            height: 100px;
            transform: translate3D(45px, 40px, 40px) rotateX(-90deg);
            background-position: -75px 0px;

            &.top {
              background-position: -75px -320px;
            }

            &.bottom {
              background-position: -75px 0px;
            }

            &.right {
              background-position: -75px -215px;
            }

            &.left {
              background-position: -75px -110px;
            }

            &.moving {
              &.top {
                animation: move-top 0.5s infinite step-end;
              }

              &.bottom {
                animation: move-bottom 0.5s infinite step-end;
              }

              &.right {
                animation: move-right 0.5s infinite step-end;
              }

              &.left {
                animation: move-left 0.5s infinite step-end;
              }
            }
          }
        }
      }
    }
  }

  .explore-interface {
    @include cover;
    z-index: 999;
  }
}
