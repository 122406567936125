.room-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  animation: fadein 0.3s;
  z-index: 310;

  .room-popup {
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 300px;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
  }

  .room-menu {
    .menu-button {
      padding: 8px;
      margin: 8px;
      font-family: "Noto Serif KR";
      font-size: 14pt;
      text-align: center;
      border: 1px solid rgba(255, 255, 255, 0.5);
      cursor: pointer;
      transition: border 0.3s, color 0.3s;

      &:hover {
        color: #fff;
        border-color: #fff;
      }
    }
  }
}
