.popup-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;

  .popup {
    width: 300px;
    height: 180px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: linear-gradient(#bbb, #eee);
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.6));
    animation: popup-fadein 0.3s;
    color: #333;
    padding: 20px;

    &.window {
      width: 400px;
      max-height: 600px;
    }

    .popup-contents {
      width: 260px;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      height: 115px;
    }

    .popup-footer {
      text-align: center;
      height: 25px;
    }

    .button {
      display: inline-block;
      width: 100px;

      &:hover {
        cursor: pointer;
        color: #000;
      }
    }

    &.numberConfirm {
      height: 200px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Noto Serif KR";
  }

  .number-confirm {
    padding: 20px 0;

    input {
      width: 50px;
      background: none;
      border: none;
      text-align: center;
      border-bottom: 1px solid #333;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }

    .min,
    .max {
      background: #333;
      color: #ddd;
      padding: 2px;
      font-size: 10pt;
      border-radius: 5px;
    }
  }

  &.message {
    h4 {
      small {
        font-size: 12pt;
        margin-left: 5px;
      }
    }

    .message-list {
      overflow: hidden;
      margin-top: 5px;
      width: 360px;
      height: 490px;
      .scroller {
        width: 390px;
        height: 490px;
        overflow-y: scroll;
      }

      .scroll-contents {
        width: 360px;
      }

      .message {
        display: flex;
        gap: 5px;
        color: #222;
        padding: 3px;
        border: 1px solid transparent;
        justify-content: space-between;

        .title {
          display: flex;
          gap: 5px;
        }

        .date,
        .from {
          font-size: 10pt;
          margin-top: 2px;
        }
        svg {
          width: 16px;
        }

        &.read {
          color: #666;
        }

        &:hover {
          border: 1px solid rgba(0, 0, 0, 0.3);
        }
      }
    }

    .message-view {
      margin-top: 10px;
      .title {
        display: flex;
        gap: 5px;
        margin-bottom: 5px;
        font-weight: bold;
        svg {
          width: 20px;
        }
      }
      .info {
        display: flex;
        justify-content: space-between;
        font-size: 10pt;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }

      .contents {
        white-space: pre-wrap;
        word-break: break-all;
        margin-bottom: 10px;
      }

      .items {
        padding-top: 10px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        .item {
          padding: 5px 0;
          display: flex;
          justify-content: space-between;
        }
        img {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }

        button {
          font-size: 10pt;
          background: #333;
          color: #ddd;
          border: none;
          margin-left: 10px;
          border-radius: 20px;
          padding: 2px 10px;
        }
      }

      .scroll-container {
        overflow: hidden;
        width: 360px;
        height: 490px;
        .scroller {
          width: 390px;
          height: 490px;
          overflow-y: scroll;
        }

        .scroll-contents {
          width: 360px;
        }
      }
    }

    .menus {
      position: absolute;
      top: 0;
      right: -125px;
      width: 125px;

      > div {
        position: relative;
        background: #333;
        color: #ddd;
        width: 40px;
        overflow: hidden;
        height: 32px;
        transition: width 0.5s;

        &:hover {
          width: 125px;
        }

        .move {
          position: absolute;
          right: 0;
          width: 125px;
          height: 32px;
          padding: 5px 10px;
        }
        .text {
          width: 80px;
          margin-right: 5px;
          float: left;
        }
        svg {
          float: left;
          width: 20px;
        }
      }
    }
  }

  .form-buttons {
    display: flex;

    button {
      background: #333;
      color: #fff;
      flex: 1;
      padding: 6px;
      border-radius: 5px;
      border: none;

      &:not(:last-of-type) {
        margin-right: 6px;
      }
    }
  }
}

.join-form {
  width: 360px;

  label {
    display: block;
    text-align: left;
    font-size: 10pt;
  }

  input,
  select {
    width: 100%;
    padding: 6px;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid #888;
    border-radius: 5px;
    transition: background 0.3s;
  }

  input {
    &:not(:placeholder-shown),
    &:focus {
      background: rgba(0, 0, 0, 0);
    }
  }

  textarea {
    width: 100%;
    padding: 6px;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid #888;
    border-radius: 5px;
    transition: background 0.3s;

    &:not(:placeholder-shown),
    &:focus {
      background: rgba(0, 0, 0, 0);
    }
  }

  .form-item {
    margin-bottom: 10px;
  }

  .counter {
    text-align: right;
    input {
      border: none;
      border-bottom: 1px solid #000;
      color: #333;
      border-radius: 0;
      width: 50px;
      text-align: center;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }
  }

  .image-input {
    button {
      width: 100%;

      background: #333;
      color: #fff;
      flex: 1;
      padding: 6px;
      border-radius: 5px;
      border: none;
    }
  }

  .logout {
    text-align: left;
    margin-bottom: 10px;
    padding: 5px 0;

    a {
      color: #666;

      &:hover {
        color: #333;
      }
    }
  }
}

@keyframes popup-fadein {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
