.login-container {
  width: 100vw;
  height: 100vh;
}

.welcome-box {
  height: 110px;
}

.cert-box {
  height: 180px;
}

.cert-box,
.welcome-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 550px;
  text-align: center;

  .text {
    margin-bottom: 20px;
  }

  input {
    padding: 6px;
    background: none;
    color: #aaa;
    border: 1px solid #888;
    border-radius: 5px;
    width: 410px;
    margin-bottom: 8px;
  }

  &.pass {
    .submit {
      margin-right: 10px;
    }
  }

  button {
    padding: 6px;
    background: none;
    color: #aaa;
    border: 1px solid #888;
    border-radius: 5px;
    width: 200px;
    margin-bottom: 8px;
    transition: border 0.5s, color 0.5s;

    &:hover {
      color: #fff;
      border: 1px solid #fff;
    }
  }
}

.login-form {
  z-index: 100;
  width: 310px;
  height: 150px;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  color: #aaa;

  &.fadeout {
    opacity: 0;
    transition: opacity 0.3s;
  }

  .text-input {
    width: 200px;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid #888;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    transition: border 0.5s;

    &:focus {
      outline: none;
      border: 1px solid #fff;
    }
  }

  .login-btn {
    width: 100px;
    height: 100px;
    margin-left: 8px;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid #888;
    border-radius: 5px;
    color: #aaa;
    transition: border 0.5s, color 0.5s;

    &:hover {
      color: #fff;
      border: 1px solid #fff;
    }
  }
}