@keyframes next-indicator {
  0% {
    margin-top: 0px;
  }

  50% {
    margin-top: -5px;
  }

  100% {
    margin-top: 0px;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.event-script-window {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 900;

  .character {
    position: absolute;
    width: 140px;
    height: 140px;
    left: 0;
    right: 0;
    bottom: calc(20% + 70px);
    margin: auto;
    border: 1px solid #fff;
    filter: drop-shadow(0 0 3px #000);
    background: rgba(0, 0, 0, 0.3);

    &.inactive {
      img {
        filter: brightness(50%);
      }
    }

    img {
      width: 138px;
      height: 138px;
    }
  }

  .script {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25%;
    z-index: 99;

    .title {
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      margin: auto;
      font-family: "Noto Serif KR";
      font-weight: bold;
      font-size: 16pt;
      margin-bottom: 10px;
      width: 300px;
      text-align: center;
      color: #fff;
      text-shadow: 0 0 5px #000, 0 0 5px #000;

      img {
        margin-top: -5px;
        filter: drop-shadow(0 0 3px #000);
      }
    }

    .script-bg {
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1)
      );
      opacity: 0.8;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
    }

    .script-contents {
      text-align: center;
      font-size: 12pt;
      text-shadow: 0 0 5px #000;
      position: absolute;
      color: #fff;
      width: 100%;
      height: 100%;
      padding: 70px 100px 20px 100px;
      z-index: 999;
    }

    .text {
      font-size: 1em;

      .animate-letter {
        animation: fadein 0.2s;
      }

      .next-indicator {
        animation: next-indicator 0.8s infinite;
      }
    }
  }

  .selection-modal {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 400px;
    z-index: 9999;
    justify-content: center;

    .selection {
      width: 100%;
      padding: 10px;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1) 100px 300px,
        rgba(0, 0, 0, 0)
      );
      color: #fff;
      margin-bottom: 20px;
      cursor: pointer;
      text-align: center;
      opacity: 0.7;
      transition: opacity 0.3s, border 0.3s;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;

      &:hover {
        opacity: 1;
        border-top: 1px solid rgb(117, 255, 158);
        border-bottom: 1px solid rgb(117, 255, 158);
      }
    }
  }

  .script-tools {
    z-index: 910;
    position: absolute;
    bottom: 30px;
    right: 30px;

    .tool-btn {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.7);
      transition: background-color 0.5s;
      padding: 6px 8px;
      color: #fff;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 1);
      }
    }
  }

  .log-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 600px;
    padding: 20px;
    height: 80%;
    z-index: 9999;
    color: #fff;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      padding: 10px 0;

      h5 {
        color: #fff;
        font-weight: bold;
        margin-right: auto;
        padding-left: 10px;
      }

      .close {
        cursor: pointer;
      }
    }

    .logs {
      overflow: hidden;
      overflow-y: auto;

      .log {
        display: flex;
        padding: 15px 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);

        .title {
          font-weight: bold;
          width: 120px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          flex-shrink: 0;
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}
