.shop-bg {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url("/static/img/shop_bg.png");
  background-size: cover;
  background-position: center center;

  .shop-container {
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 100px;
    right: 0;
    margin: auto;
    width: 1200px;
    height: 650px;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6) 0%,
      transparent 100%
    );
    padding: 20px 50px 20px 300px;

    .categories {
      margin-bottom: 20px;
      .category {
        display: inline-block;
        padding: 3px 10px;
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid #ddd;
        border-radius: 40px;
        margin-right: 6px;
        color: #ddd;
        transition: background 0.3s, color 0.3s;

        &.selected,
        &:hover {
          background: #ddd;
          color: #111;
        }
      }
    }

    .contents {
      display: flex;
      justify-content: space-between;

      .contents-left {
        width: 550px;
        .list {
          height: 440px;
          width: 550px;
          overflow: hidden;

          .scroller {
            height: 420px;
            width: 580px;
            overflow-y: scroll;
          }
          .scroll-contents {
            width: 550px;
          }

          .item {
            display: flex;
            justify-content: space-between;
            transition: background 0.3s, color 0.3s;

            .item-wrap {
              width: 260px;
              border: none;
            }
            .limit {
              padding-top: 2px;
            }
            .price {
              padding-top: 2px;
              text-align: right;
              width: 120px;
              padding-right: 10px;
            }

            &:hover {
              font-weight: bold;
            }

            &.selected {
              color: #333;
              background: #ddd;
            }
          }
        }
        .npc-message {
          font-size: 14pt;
          .text {
            padding: 10px 0;
          }
        }
      }

      .contents-right {
        height: 600px;
        width: 220px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .money {
          text-align: center;
          strong {
            font-size: 14pt;
          }
        }
        .tool {
          text-align: center;
          button {
            display: inline-block;
            cursor: pointer;
            font-weight: bold;
            background: #ddd;
            color: #333;
            width: 220px;
            text-align: center;
            font-family: "Noto Serif KR";
            border-radius: 40px;
            transition: background 0.3s;
            font-size: 14pt;
            border: none;
            padding: 10px;
            &:hover {
              background: #fff;
            }
          }
          .stock {
            margin-bottom: 20px;
          }
          .cost {
            margin-bottom: 20px;
          }

          .counter {
            margin-bottom: 20px;
            input {
              background: rgba(0, 0, 0, 0.3);
              border: none;
              border-bottom: 1px solid #000;
              color: #ddd;
              width: 80px;
              text-align: center;

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              -moz-appearance: textfield;
            }
          }
        }
      }
    }
    .npc {
      position: absolute;
      bottom: 0;
      left: -300px;
      img {
        width: 600px;
      }
    }
    .list-container {
      position: absolute;
    }
  }
}

.craft-container {
  position: relative;
  top: 60px;
  left: 0;
  right: 0;
  margin: auto;
  width: 1200px;
  padding: 30px 50px;
  display: flex;
  gap: 20px;
  margin-bottom: 30px;

  h4 {
    font-family: "Noto Serif KR";
    font-size: 18pt;
    color: #eaeaea;
    margin-bottom: 10px;
    small {
      color: #ddd;
      margin-left: 10px;
      font-family: "Pretendard";
      font-weight: 200;
      font-size: 12pt;
    }
  }

  .list-container {
    width: 300px;
    .list {
      width: 300px;
      height: calc(100vh - 200px);
      overflow: hidden;

      .list-scroller {
        overflow-y: scroll;
        height: 100%;
        width: 320px;
      }
      .scroll-contents {
        width: 300px;
      }

      .item {
        img {
          width: 24px;
          height: 24px;
        }
        background: rgba(0, 0, 0, 0.2);
        cursor: pointer;
        padding: 15px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        transition: border-color 0.3s;
        margin-bottom: 10px;
        color: #ddd;
        position: relative;
        display: flex;
        justify-content: space-between;

        .title {
          font-weight: bold;
          color: #eaeaea;
        }
        .time {
          color: #777;
          font-size: 10pt;
        }

        &.selected,
        &:hover {
          border-color: #ddd;
        }
      }
    }
  }
  .preview-container {
    flex: 1;

    .no-selection {
      text-align: center;
      padding: 150px 0px;
    }
    .preview {
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.3);
      width: 100%;
      margin-bottom: 20px;
      padding: 20px;
      display: flex;
      gap: 20px;
      min-height: 300px;
      justify-content: space-between;

      .ingrediants {
        width: 450px;
      }
      .buttons {
        width: 210px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        .time {
          font-size: 14pt;
          padding-top: 30px;
          margin-bottom: 10px;
          text-align: center;
        }
        .button {
          margin-left: 10px;
          display: inline-block;
          cursor: pointer;
          font-weight: bold;
          background: #ddd;
          color: #333;
          text-align: center;
          font-family: "Noto Serif KR";
          border-radius: 40px;
          transition: background 0.3s;
          width: 200px;
          font-size: 14pt;
          padding: 10px;
          border: none;

          &:hover {
            background: #fff;
          }
        }
      }
    }

    .slots {
      display: flex;
      justify-content: space-between;
    }
    .slot {
      width: 255px;
      padding: 20px;
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.3);
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      img {
        width: 24px;
        height: 24px;
      }

      &.empty .info {
        text-align: center;
      }
      .info {
        font-size: 11pt;
        color: #777;
      }
      .buttons {
        text-align: right;
        button {
          margin-left: 10px;
          display: inline-block;
          cursor: pointer;
          font-weight: bold;
          background: #ddd;
          color: #333;
          text-align: center;
          font-family: "Noto Serif KR";
          border-radius: 40px;
          transition: background 0.3s;
          font-size: 11pt;
          border: none;
          padding: 3px 10px;
          &:hover {
            background: #fff;
          }
        }
      }
    }
  }
}
