@mixin cover {
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  min-width: 1680px;
  min-height: 900px;
  max-width: 1920px;
}

.application {
  @include cover;
  padding-top: 60px;
  padding-left: 300px;

  h3 {
    color: #fff;
    font-family: "Noto Serif KR";
    font-size: 24pt;
  }

  h4 {
    color: #ddd;
    font-family: "Noto Serif KR";
    font-size: 20pt;
  }

  .lnb-cover {
    @include cover;
    top: 60px;
    position: fixed;
    min-height: 0;
    overflow: visible;
  }

  .lnb-container {
    width: 300px;
    position: absolute;
    left: 0px;

    .lnb {
      // text-align: right;
      padding: 20px;
      margin-left: 30px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      svg {
        width: 16px;
        margin-left: 3px;
      }

      .badge {
        font-size: 10pt;
        margin: 0 5px;
        width: auto;
        padding: 3px 6px;
      }
    }
  }

  .contents {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding: 40px;
    flex: 1;

    .main {
      .status {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-bottom: 30px;
        display: flex;

        .column {
          flex: 1;

          &.info {
            text-align: right;
          }
        }

        .character-name {
          font-size: 18pt;
        }

        .state {
          font-size: 12pt;
          display: inline-block;
          padding: 2px 20px;
          background: #ddd;
          border-radius: 20px;
          margin-right: 10px;
          color: #000;
        }

        .delete {
          font-size: 10pt;
          cursor: pointer;

          &:hover {
            color: #ff0000;
          }
        }
      }

      .bottom-area {
        padding-top: 30px;
        display: flex;

        a {
          text-decoration: underline;

          svg {
            width: 18px;
          }
        }

        .notice {
          flex: 1;
          padding-right: 20px;

          p {
            margin-top: 10px;
            font-size: 14pt;
            word-break: break-all;
          }
        }

        .statistic {
          flex: 1;
          padding-left: 20px;

          .chart {
            margin: 30px 0;
          }
        }
      }
    }
  }

  .badge {
    background: #aaa;
    font-size: 12pt;
    color: #000;
    border-radius: 11px;
    margin-right: 8px;
    width: 110px;
    padding: 5px;
  }

  .form {
    margin-bottom: 50px;

    & > p {
      margin-bottom: 5px;

      svg {
        width: 18px;
        margin-right: 5px;
      }
    }

    form {
      margin-top: 25px;
    }

    label {
      svg {
        width: 16px;
        margin-left: 4px;
      }
    }

    .input-box {
      margin-bottom: 8px;
      padding: 0 5px;
    }

    .form-control {
      background: rgba(0, 0, 0, 0.3);
      border: 1px solid #888;
      border-radius: 5px;
      padding: 6px;
      color: #fff;
      transition: border 0.5s;

      &::placeholder {
        color: #999;
      }

      &:focus {
        outline: none;
        border: 1px solid #fff;
      }
    }

    .image-input {
      button {
        background: rgba(0, 0, 0, 0.3);
        border: 1px solid #888;
        border-radius: 5px;
        padding: 6px 12px;
        color: #fff;
        margin-right: 5px;
        width: 100%;

        svg {
          width: 20px;
        }
      }

      .buttons {
        text-align: right;
      }
    }

    textarea {
      height: 200px;
    }

    .form-select {
      background-color: rgba(0, 0, 0, 0.3);
      border: 1px solid #888;
      color: #fff;
      position: relative;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");

      option {
        background: #222;
        color: #eee;
      }
    }

    .status-input {
      .status-control {
        text-align: center;
      }

      .status-all {
        font-size: 24pt;
      }

      .value {
        font-size: 24pt;
      }

      svg.up,
      svg.down {
        cursor: pointer;
        transform: scale(1.3);
        margin: -7px 3px 0 3px;

        &:active {
          margin-top: -6px;
        }
      }
    }

    .commitment {
      p {
        margin: 0;
      }

      margin-bottom: 20px;
      position: relative;

      .form-check {
        font-size: 10pt;
        position: absolute;
        bottom: 0;

        input {
          margin-right: 10px;
        }
      }
    }

    .form-buttons {
      text-align: center;

      button {
        border: 1px solid rgba(255, 255, 255, 0.6);
        background: rgba(0, 0, 0, 0.3);
        color: rgba(255, 255, 255, 0.6);
        width: 300px;
        padding: 10px;
        border-radius: 50px;
        transition: color 0.3s, border-color 0.3s;
        margin: 0 10px;

        &:hover {
          color: #fff;
          border-color: #fff;
        }
      }
    }

    .preview-container {
      top: 0;
      left: 0;
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 100;
      filter: drop-shadow(0 0 5px #000);

      h5 {
        color: #fff;
      }

      .preview {
        background: #000;
        padding: 20px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;

        .close {
          cursor: pointer;
          position: absolute;
          right: 20px;
          top: 20px;

          svg {
            margin-top: -3px;
            width: 20px;
          }

          &:hover {
            color: #fff;
          }
        }

        .image {
          background: #999;
          position: relative;
        }

        &.thumbnail_img {
          width: 400px;
          height: 240px;

          .image {
            margin-left: 120px;
            width: 120px;
            height: 120px;

            .overlay {
              position: absolute;
              left: 10px;
              top: 10px;
              border-radius: 100px;
              border: 3px dotted #fff;
              width: 100px;
              height: 100px;
            }
          }
        }

        &.body_img {
          width: 1000px;
          height: 1000px;

          .image {
            height: 890px;

            img {
              object-fit: scale-down;
            }
          }
        }

        &.battle_img {
          width: 440px;
          height: 360px;

          .image {
            width: 400px;
            height: 250px;
          }

          .overlay {
            position: absolute;
            left: calc(50% - 60px);
            top: 60px;
            border-radius: 100px;
            border: 3px dotted #fff;
            width: 120px;
            height: 120px;
          }
        }

        &.cutscene_img {
          width: 1240px;
          height: 475px;

          .image {
            width: 1200px;
            height: 400px;

            img {
              object-fit: scale-down;
              object-position: bottom;
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
