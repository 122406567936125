.search-select{
  position:relative;

  &.active {
    input{
      border-radius: 0.25rem 0.25rem 0 0;
    }
    ul{
      display:block;
      max-height:300px;
      overflow-y:auto;
    }
  }

  ul{
    padding:0;
    margin:0;
    display:none;
    border:1px solid #96bdf2;
    border-top:none;
    border-radius: 0 0 0.25rem 0.25rem;
    position:absolute;
    z-index:999;
    width:100%;
    
    li{
      list-style: none;
      padding:8px 16px;
      z-index:1000;
      color:#000;
      background:#fff;
      cursor: pointer;

      &:hover{
        background: #d0e3fd;
      }
      &:last-of-type{
        border-radius: 0 0 0.25rem 0.25rem;
      }
    }
  }

  &.inverse {
    ul {
      bottom:100%;
      border:1px solid #96bdf2;
      border-bottom:none;
      border-radius: 0.25rem 0.25rem 0 0;
    }
    li {
      &:last-of-type{
      border-radius: 0.25rem 0.25rem 0 0;
      }
    }
  }
}