.document {
  color: #ccc;
  height: 100vh;

  .panels {
    width: 1000px;
    height: 600px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    gap: 10px;

    .panel {
      flex: 1;
      border: 1px solid #999;
    }
  }

  .doc-container {
    position: relative;
    top: 100px;
    left: 0;
    right: 0;
    margin: auto;
    width: 800px;
    padding-bottom: 50px;
  }

  h3,
  h4,
  h5,
  h6 {
    font-family: "Noto Serif KR";
    color: #eaeaea;
  }

  h3 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 24pt;
    text-shadow: 0 0 5px #000;
  }

  h4 {
    margin-bottom: 20px;
  }

  .text {
    margin-bottom: 10px;
  }

  .synopsis {
    background: rgba(0, 0, 0, 0.3);
    padding: 50px;
    margin-bottom: 20px;
  }

  small {
    color: #888;
  }

  section {
    margin-bottom: 50px;
  }

  .block {
    margin-bottom: 50px;
  }

  .note {
    font-size: 11pt;
    color: #999;
    padding-top: 5px;
    margin-bottom: 15px;

    p {
      margin-bottom: 5px;
    }
  }

  .image-container {
    text-align: center;
    margin-bottom: 30px;
    width: 1000px;
    margin-left: -100px;

    img {
      max-width: 100%;
      border: 1px solid #666;
    }
  }

  q {
    display: block;
    text-align: center;
    color: #eaeaea;
    font-family: "Noto Serif KR";
    margin-bottom: 20px;
    font-size: 11pt;
  }

  p {
    padding-left: 25px;
    background: url("/static/img/doc_head.png") no-repeat 3px 3px;
    background-size: 16px;
    margin-bottom: 15px;
  }
}
