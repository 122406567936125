$min-width: 1680px;
$min-height: 900px;

.chat-bubble {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;

  &.fadeout {
    animation: bubble_fadein 0.3s reverse forwards;
  }

  &.fadein {
    animation: bubble_fadein 0.3s;
  }

  &.bottom {
    &::after {
      content: "";
      width: 0;
      height: 0;
      display: block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;

      border-bottom: 6px solid black;
      position: absolute;
      top: -6px;
      left: calc(50% - 6px);
    }
  }
  &.top {
    &::after {
      content: "";
      width: 0;
      height: 0;
      display: block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;

      border-top: 6px solid #ddd;
      position: absolute;
      bottom: -6px;
      left: calc(50% - 6px);
    }
  }

  .bubble {
    max-width: 200px;
    word-break: break-all;
    display: inline-block;
    background: rgba(0, 0, 0, 0.7);
    border: 2px solid #ddd;
    border-radius: 10px;
    color: #ddd;
    text-align: center;
    padding: 10px;
  }

  @keyframes bubble_fadein {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

.battle-room {
  width: 100vw;
  height: 100vh;
  position: absolute;
  min-width: $min-width;
  min-height: $min-height;
  top: 0;
  left: 0;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;

  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 100;
    max-width: 1920px;
    min-width: $min-width;
    min-height: $min-height;
    overflow: hidden;
    padding: 0;
  }

  .chat {
    position: absolute;
    right: 0;
    top: 30%;
    width: 350px;
    height: 390px;
    padding: 10px;
    z-index: 200;

    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 50%
    );

    .chat-container {
      width: 330px;
      height: 340px;
      overflow: hidden;
    }

    .scroller {
      width: 370px;
      height: 340px;
      overflow-y: scroll;
      overflow-x: hidden;

      ul,
      li {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      li {
        margin-bottom: 5px;
        display: flex;
      }

      .name {
        width: 100px;
        height: 24px;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 20px;
        color: #fff;
        font-size: 12pt;
        text-align: center;
      }

      .text {
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-use-select: text;
        user-select: text;
        word-break: break-all;
        color: #eee;
        font-size: 12pt;
        padding-left: 10px;
        width: 230px;
      }
    }

    .chat-input {
      border-radius: 10px;
      height: 30px;
      background: rgba(0, 0, 0, 0.5);
      position: relative;

      ::before {
        margin-left: 4px;
        content: "";
        display: inline-block;
        background-image: url("/static/img/chat_icon.png");
        background-position: center bottom;
        width: 26px;
        height: 28px;
      }

      input {
        position: absolute;
        top: 2px;
        left: 35px;
        width: 290px;
        background: none;
        border: none;
        color: #fff;
      }

      button {
        display: none;
      }
    }
  }

  .command-waiting {
    width: 300px;
    background: linear-gradient(
      to right,
      transparent,
      rgba(0, 0, 0, 0.5) 20%,
      rgba(0, 0, 0, 0.5) 80%,
      transparent
    );
    position: absolute;
    left: 120px;
    padding: 10px 20px;
    top: calc(50% - 30px);
    color: #fff;

    svg {
      margin-right: 10px;
    }
  }
  .commands {
    position: absolute;
    left: 120px;
    top: calc(50% - 150px);
    width: 420px;
    height: 300px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;

    animation: command-fade-in 0.3s;

    @keyframes command-fade-in {
      0% {
        opacity: 0;
        transform: translateX(30px);
      }

      100% {
        opacity: 1;
        transform: translateX(0px);
      }
    }

    .command {
      cursor: pointer;
      position: relative;
      height: 42px;
      width: 400px;
      background: url("/static/img/command_bg.png");
      background-repeat: no-repeat;
      background-position: 16px 0;
      margin-bottom: 3px;
      transition: transform 0.3s;

      &.selected {
        &::after {
          content: "";
          z-index: 120;
          display: block;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          border: 1px solid #fff;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          animation: execute_command 0.3s;
        }
      }

      &:hover,
      &.selected {
        transform: translate3d(15px, 0, 0);

        .key-icon.selected {
          opacity: 1;
        }
      }

      .key-icon {
        pointer-events: all;
        display: inline-block;
        width: 42px;
        height: 42px;
        box-sizing: border-box;
        text-align: center;
        padding-top: 6px;
        padding-right: 2px;
        background: url("/static/img/command_icon_black.png");
        background-repeat: no-repeat;
        background-position: -1px -1px;
        font-size: 16pt;
        color: #ddd;
        font-family: "Noto Serif KR";

        &.selected {
          transition: opacity 0.3s;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          background-image: url("/static/img/command_icon_white.png");
          color: #333;
        }
      }

      .text {
        pointer-events: all;
        padding-left: 8px;
        font-size: 14pt;
        color: #ddd;
        display: inline-block;
      }
    }
  }

  .round {
    position: absolute;
    top: 100px;
    left: 60px;
    width: 107px;
    height: 106px;
    box-sizing: border-box;
    background: url("/static/img/turn_icon.png");
    padding-top: 25px;
    text-align: center;
    z-index: 200;

    &.counter {
      animation: round_counter 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    }

    span {
      font-family: "Noto Serif KR";
      color: #fff;
      font-size: 18pt;
    }

    @keyframes round_counter {
      0% {
        transform: scale(0.75);
      }

      100% {
        transform: scale(1);
      }
    }
  }

  .wave {
    position: absolute;
    top: 120px;
    left: calc(50% - 130px);
    width: 260px;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    z-index: 200;
    color: rgba(255, 255, 255, 0.5);

    font-family: "Noto Serif KR";
    background: linear-gradient(
      to right,
      transparent,
      rgba(0, 0, 0, 0.3) 20%,
      rgba(0, 0, 0, 0.3) 80%,
      transparent
    );
    span {
      color: #fff;
      font-size: 16pt;
    }
  }

  .initiatives {
    position: absolute;
    top: 120px;
    left: 180px;
    z-index: 200;
    max-width: 500px;
    .title {
      font-size: 12pt;
      color: #fff;
      font-family: "Noto Serif KR";
      text-shadow: 0 0 5px #000;
    }
    .initiative {
      display: inline-block;
      span {
        font-size: 10pt;
        background: #111;
        padding: 1px 3px;
        margin-right: 5px;
      }

      &:not(:first-of-type)::before {
        content: "▶";
        font-size: 6pt;
        text-shadow: 0 0 5px #000;
        color: #fff;
        margin-right: 5px;
        display: inline-block;
      }
    }
  }

  .enemies {
    width: 900px;
    height: 300px;
    position: absolute;
    top: calc(50% - 150px);
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .unit.enemy {
      min-width: 200px;
      padding: 20px;
      position: relative;

      .sprite {
        &.damaged {
          animation: damaged 1s;
        }

        &.dead {
          filter: grayscale(100%);
          animation: dead 0.6s;
        }

        img {
          max-width: 600px;
        }

        text-align: center;
      }

      .targeting {
        z-index: 130;
      }

      .info {
        width: 240px;
        height: 70px;
        padding: 10px;
        display: none;
        position: absolute;
        left: calc(50% - 130px);
        bottom: 100%;
        background: rgba(0, 0, 0, 0.5);
        animation: tooltip-fadein 0.6s;
        z-index: 130;

        .name {
          font-family: "Noto Serif KR";
          color: #fff;
          font-size: 12pt;
        }

        .buffs {
          position: absolute;
          right: 10px;
          top: 7px;
        }

        .hp {
          margin-top: 7px;
          width: 220px;
          position: relative;
          background: rgba(0, 0, 0, 0.3);
        }

        .arrow {
          position: absolute;
          margin: auto;
          bottom: -8px;
          left: 0;
          right: 0;
          width: 23px;
          height: 15px;
          animation: arrow_sprite 0.5s alternate infinite step-end;
          background-image: url("/static/img/arrow_sprite.png");
        }
      }

      &:hover {
        .info {
          display: block;
        }
      }
    }
  }

  .allies {
    position: absolute;
    bottom: 50px;
    margin: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    width: 90%;

    .unit.ally {
      width: 400px;
      height: 360px;
      position: relative;

      .portrait {
        margin-top: 50px;
        margin-left: 15px;
        width: 400px;
        height: 250px;
        overflow: visible;
        position: relative;
        .chat-bubble {
          bottom: 250px;
        }

        img {
          width: 400px;
          mask-image: linear-gradient(
            180deg,
            #000 0%,
            #000 80%,
            transparent 100%
          );
        }

        .standby-overlay {
          animation: fadein 0.3s;
          width: 400px;
          height: 350px;
          background: url("/static/img/battle_standby.png");
          position: absolute;
          top: -80px;
          left: 0px;
        }

        &.damaged {
          animation: damaged 1s;
        }

        &.dead {
          filter: grayscale(100%);
          animation: dead 0.6s;
        }
      }

      .info {
        width: 350px;
        margin: 0 25px;
        height: 70px;
        background: url("/static/img/info_gage.png");
        background-repeat: no-repeat;
        background-position: right top;
        position: relative;
        z-index: 130;

        .hp {
          width: 280px;
          position: absolute;
          top: 5px;
          left: 65px;

          .sp-bar {
            position: absolute;
            left: 25px;
            top: 4px;

            .sp-dot {
              float: left;
              width: 12px;
              height: 12px;
              border: 2px solid rgba(255, 255, 255, 0.8);
              border-radius: 24px;
              margin-right: 5px;

              .fill {
                margin-left: 1px;
                margin-top: 1px;
                content: "";
                display: block;
                background: #fff;
                width: 6px;
                height: 6px;
                border-radius: 10px;

                &.use {
                  animation: dot-blink 1s linear infinite alternate;
                }

                @keyframes dot-blink {
                  0% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              }
            }
          }

          .hp-bar {
            height: 22px;
            background: url("/static/img/hp_bar.png");
            background-repeat: no-repeat;
            transition: width 0.3s;
          }

          .hp-text {
            position: absolute;
            right: 7px;
            top: -1px;
            color: #999;

            strong {
              color: #fff;
              font-weight: 400;
            }
          }

          .shield-text {
            font-size: 10pt;
            color: #fff;
            svg {
              margin-top: -2px;
              width: 12px;
            }
          }
        }

        .name {
          font-family: "Noto Serif KR";
          color: #fff;
          font-size: 16pt;
          position: absolute;
          bottom: 7px;
          left: 90px;
        }

        .buffs {
          width: 350px;
          text-align: right;
          position: absolute;
          right: 0px;
          top: -30px;
        }

        .emblem {
          position: absolute;
          top: -9px;
          left: 11px;
          width: 76px;
          height: 76px;
          background: url("/static/img/info_circle.png");

          .view-button {
            cursor: pointer;
            position: absolute;
            width: 32px;
            height: 32px;
            background: #aaa;
            border-radius: 36px;
            top: 0px;
            left: -8px;

            svg {
              color: #333;
              width: 25px;
              height: 25px;
              margin: 3px 0 0 3px;
            }
          }

          .emblem-img {
            border-radius: 100px;
            margin-top: 6px;
            margin-left: 6px;
            width: 65px;
            height: 65px;
            background-size: 110%;
            background-position: center center;

            &.tank {
              background-image: url("/static/img/tank_white_min.png");
            }

            &.sup {
              background-image: url("/static/img/sup_white_min.png");
            }

            &.dps {
              background-image: url("/static/img/dps_white_min.png");
            }

            &.heal {
              background-image: url("/static/img/heal_white_min.png");
            }
          }

          .emblem-overlay {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 76px;
            height: 76px;
            background: url("/static/img/info_overlay.png");
          }
        }
      }
    }
  }

  .targeting {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.ally {
      .circle {
        width: 180px;
        height: 180px;
      }

      .circle-inner {
        width: 110px;
        height: 110px;
        background-size: 110px;
        margin: 35px 0 0 35px;
      }

      .circle-outer {
        width: 180px;
        height: 180px;
        background-size: 180px;
      }
    }

    &.enemy {
      .circle {
        width: 120px;
        height: 120px;
      }

      .circle-inner {
        width: 80px;
        height: 80px;
        background-size: 80px;
        margin: 20px 0 0 20px;
      }

      .circle-outer {
        width: 120px;
        height: 120px;
        background-size: 120px;
      }
    }

    .circle {
      cursor: pointer;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      transform: scale(0.8);
      opacity: 0.5;
      transition: transform 0.3s, opacity 0.3s;
    }

    .circle-inner {
      position: absolute;
      background-image: url("/static/img/target1.png");
      animation: scale 1s infinite alternate;

      @keyframes scale {
        0% {
          transform: scale(1);
        }

        100% {
          transform: scale(0.9);
        }
      }
    }

    .circle-outer {
      position: absolute;
      background-image: url("/static/img/target2.png");
      animation: rotate 6s infinite;

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(180deg);
        }
      }
    }

    &.active {
      .circle {
        transform: scale(1);
        opacity: 1;
      }
    }

    animation: fade-in-circle 0.3s;

    @keyframes fade-in-circle {
      0% {
        transform: scale(0);
      }

      100% {
        transform: scale(1);
      }
    }
  }

  .effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 110;
    pointer-events: none;

    .effect-sprite {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .effect-text {
    position: absolute;
    pointer-events: none;
    top: calc(10%);
    left: calc(50% + 50px);
    white-space: nowrap;
    color: #fff;
    font-family: "Noto Serif KR";
    z-index: 120;

    & > div {
      height: 20px;
      overflow: visible;
    }

    @keyframes text-animation {
      0% {
        opacity: 1;
        transform: translateY(5px);
      }

      30% {
        opacity: 1;
      }

      100% {
        opacity: 0;
        transform: translateY(-10px);
      }
    }

    .damage {
      font-size: 24pt;
      font-weight: bold;
      text-shadow: 0px 0px 5px #922320, 0px 0px 5px #922320;
      opacity: 0;
      animation: text-animation 3s;

      &.crit {
        font-size: 28pt;
      }

      .crit-text {
        font-size: 12pt;
        margin-bottom: -10px;
      }
    }

    .dodge,
    .block {
      font-size: 18pt;
      text-shadow: 0px 0px 5px #000;
      animation: text-animation 3s;
      opacity: 0;
    }

    .sp {
      font-size: 18pt;
      text-shadow: 0px 0px 5px #3d87ff;
      animation: text-animation 3s;
      opacity: 0;
    }

    .heal {
      font-size: 18pt;
      text-shadow: 0px 0px 5px #6bbc53;
      animation: text-animation 3s;
      opacity: 0;
    }

    .buff-item {
      padding: 2px;
      font-size: 14pt;
      min-width: 100px;
      animation: text-animation 3s;
      opacity: 0;
      height: 36px;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 10%,
        rgba(0, 0, 0, 0.5) 90%,
        rgba(0, 0, 0, 0) 100%
      );

      .buff {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }

  .command-notify {
    position: absolute;
    top: 90px;
    left: calc(50% - 298px);
    animation: notify-fadein 2s linear;
    opacity: 0;
    z-index: 300;

    .portrait {
      margin-left: 135px;
      width: 320px;
      height: 200px;
      overflow: hidden;
      text-align: center;

      img {
        width: 320px;
      }
    }

    .text {
      width: 595px;
      height: 93px;
      background: url("/static/img/battle_command.png");
      color: #fff;
      font-family: "Noto Serif KR";
      text-align: center;
      padding: 15px;
      text-shadow: 0px 0px 5px #000000, 0px 0px 5px #000000;

      .actor-name {
        font-size: 12pt;
      }

      .command-name {
        margin-top: -5px;
        font-size: 24pt;
      }
    }

    @keyframes notify-fadein {
      0% {
        opacity: 0;
        transform: translateX(-100px);
      }

      10% {
        opacity: 1;
        transform: translateX(-10px);
      }

      90% {
        opacity: 1;
        transform: translateX(10px);
      }

      100% {
        opacity: 0;
        transform: translateX(100px);
      }
    }
  }

  .hp-bar {
    height: 22px;
    background: url("/static/img/hp_bar.png");
    background-repeat: no-repeat;
  }

  .hp-text {
    position: absolute;
    right: 7px;
    top: -1px;
    color: #999;

    strong {
      color: #fff;
      font-weight: 400;
    }
  }

  .buff {
    display: inline-block;
    width: 26px;
    height: 26px;
    margin-left: 3px;
    border: 1px solid #777;
    overflow: hidden;
    line-height: 0;
    position: relative;

    .counter {
      width: 26px;
      height: 26px;
      text-align: center;
      font-weight: bold;
      padding-top: 12px;
      top: 0;
      left: 0;
      position: absolute;
      font-size: 14pt;
      color: #fff;
    }

    &.good {
      border: 1px solid #547a2f;
    }

    &.bad {
      border: 1px solid #b62d0d;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  .background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    min-width: $min-width;
    min-height: $min-height;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 10;
    }

    .pattern {
      position: absolute;
      z-index: 20;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("/static/img/battle_pattern.png");
      background-size: auto 100%;
      background-repeat: repeat-x;
    }
  }

  .menu {
    z-index: 200;
    position: absolute;
    top: 125px;
    right: 60px;
    padding: 17px;
    color: #fff;
    cursor: pointer;
    background: url("/static/img/menu_icon.png");
    width: 60px;
    height: 60px;
  }

  .room-menu {
    .info {
      padding: 20px;
      height: 535px;
      overflow: hidden;
      .scroller {
        width: calc(100% + 50px);
        overflow-y: scroll;
        height: 495px;

        .contents {
          width: 410px;
        }
      }

      h5 {
        font-family: "Noto Serif KR";
        color: #ccc;
      }
      hr {
        margin: 20px 0;
      }
      .status {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .stat {
          width: 220px;

          .stat-name {
            font-weight: bold;
          }
        }
      }

      .skill-component {
        border-color: #666;
      }
    }
  }

  @keyframes damaged {
    0% {
      filter: opacity(1) drop-shadow(0 0 0 red);
    }

    50% {
      filter: opacity(0.5) drop-shadow(0 0 0 red);
    }

    100% {
      filter: opacity(1) drop-shadow(0 0 0 red);
    }
  }

  @keyframes tooltip-fadein {
    0% {
      opacity: 0;
      transform: translate3d(0, 10px, 0);
    }

    100% {
      opacity: 100;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes arrow_sprite {
    0% {
      background-position: 0px 0px;
    }

    25% {
      background-position: 0px -15px;
    }

    50% {
      background-position: 0px -30px;
    }

    75% {
      background-position: 0px -45px;
    }
  }

  @keyframes execute_command {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(5);
      opacity: 0;
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes dead {
    0% {
      filter: grayscale(0%);
    }

    100% {
      filter: grayscale(100%);
    }
  }
}
