.info-popup {
  z-index: 1100;
  position: fixed;
  width: 500px;
  height: 100%;
  right: 0;
  top: 0;
  background: linear-gradient(#bbb, #eee);
  transform: translate3d(100%, 0, 0);
  transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  box-sizing: border-box;
  padding: 30px 30px 60px 30px;
  color: #333;

  img {
    max-width: 100%;
  }

  .popup-scroll-container {
    width: 440px;
    height: 100%;
    overflow: hidden;
  }

  .popup-scroller {
    width: 470px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .popup-contents {
    width: 440px;
  }

  .popup-title {
    position: absolute;
    bottom: 30px;
    right: 30px;
    display: inline-block;
    background: #000;
    padding: 0 5px;
    font-size: 12pt;
    color: #fff;
  }

  .profile-container {
    section {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

      &:last-of-type {
        border-bottom: none;
      }

      h5 {
        text-align: center;
        font-family: "Noto Serif KR";
        font-size: 20pt;
      }

      .outfit-buttons {
        svg {
          width: 18px;
        }
      }

      .desc {
        text-align: center;
        font-size: 10pt;
        color: #888;

        svg {
          width: 18px;
        }
      }

      p {
        padding: 10px 0;
        margin: 0;
        word-break: break-all;
      }

      a {
        color: #333;
        &:hover {
          text-decoration: underline;
          color: #000;
        }
      }
    }
  }

  .relation-container {
    .relation-form {
      padding: 15px;

      .input {
        margin-bottom: 10px;

        .form-label {
          margin: 0;
        }

        .form-control {
          background: rgba(0, 0, 0, 0.2);
          border-color: rgba(0, 0, 0, 0.2);
        }
      }

      .buttons {
        display: flex;
        gap: 5px;

        button {
          flex: 1;
          border: none;
          background: #333;
          color: #fff;
          border-radius: 10px;
          padding: 5px;
        }
      }
    }

    .add-relation {
      border: 1px dashed rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      margin-bottom: 20px;

      .add-button {
        cursor: pointer;
        text-align: center;
        font-size: 14pt;
        padding: 10px;
      }
    }

    .no-relation {
      width: 100%;
      overflow: hidden;
      text-align: center;
      padding: 50px 0;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 20px;
    }

    .relation {
      width: 100%;
      overflow: hidden;
      height: 150px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      margin-bottom: 10px;

      &.show {
        height: auto;
      }

      .summary {
        position: relative;
        cursor: pointer;
        height: 150px;

        .title {
          background: #333;
          display: inline-block;
          color: #fff;
          padding: 2px 10px;
          border-radius: 15px;
          position: absolute;
          top: 30px;
          left: 15px;
        }

        .character-name {
          position: absolute;
          top: 60px;
          left: 17px;

          .text {
            font-size: 20pt;
            font-weight: bold;
          }

          .eng {
            margin-top: -5px;
            font-size: 12pt;
          }
        }

        .character-image {
          height: 150px;
          position: absolute;
          top: 0;
          right: -20px;
        }
      }

      .desc {
        position: relative;
        background: #333;
        color: #ddd;
        padding: 20px;

        a {
          text-decoration: underline;

          &:hover {
            color: #fff;
          }
        }

        .update-icon {
          font-size: 10pt;
          text-align: right;

          span {
            cursor: pointer;

            &:hover {
              color: #fff;
            }
          }

          svg {
            width: 20px;
          }
        }
      }
    }
  }

  .mailbox {
    border: 1px solid #333;
    padding: 6px;
    border-radius: 10px;
    transition: border-color 0.3s;
    margin-bottom: 10px;
    transition: background 0.3s;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .combat-container {
    .status {
      .stat-row {
        padding: 5px 0;
      }

      .stat-name {
        text-align: right;
        font-weight: bold;
      }
    }

    .skill-slot {
      cursor: pointer;
      margin-bottom: 10px;
    }

    .outfits {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .outfit {
        cursor: pointer;
        width: 100px;
        height: 400px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        transition: border 0.3s;
        position: relative;

        &:hover {
          .setting {
            display: block;
          }
        }
        .setting {
          display: none;
          padding: 3px;
          position: absolute;
          top: 0;
          right: 0;
        }

        .image {
          width: 98px;
          height: 398px;
          background-size: auto 800px;
          background-position: center top;
          background-repeat: no-repeat;
          overflow: hidden;

          .scale {
            transform: scale(0.7);
            transform-origin: center top;
          }

          .offset {
            position: relative;
            left: -550px;
          }

          img {
            position: relative;
            max-width: none;
            width: 1200px;
            height: 1200px;
            object-fit: contain;
            object-position: top;
          }
        }

        .textbox {
          position: absolute;
          width: 98px;
          height: 398px;
          top: 0;
          left: 0;
          background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.8) 0%,
            transparent 50%
          );

          .title {
            position: absolute;
            left: 0;
            bottom: 10px;
            width: 98px;
            font-weight: bold;
            color: #ddd;
            text-align: center;
          }
        }
        .selected {
          position: absolute;
          left: 0px;
          top: 10px;
          font-size: 10pt;
          background: #333;
          color: #ddd;
          padding: 3px 5px;
        }

        &:hover {
          border: 1px solid rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .quest-container {
    .quest-list {
      .quest {
        padding: 8px;
        margin-bottom: 5px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        .title {
          color: #333;
        }

        &.selected .contents {
          display: block;
        }
        .contents {
          display: none;
          padding-top: 10px;
          .desc {
            white-space: pre-wrap;
            word-break: break-all;
          }
          .condition {
            padding-bottom: 5px;
          }
          .date {
            padding-bottom: 5px;
            color: #777;
            font-size: 10pt;
          }
          .buttons {
            text-align: right;

            button {
              border: none;
              background: #333;
              color: #ddd;
              border-radius: 20px;
              padding: 5px 10px;
              font-size: 10pt;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  .box {
    margin-bottom: 20px;

    .box-title {
      display: flex;

      .title {
        font-family: "Noto Serif KR";
        font-size: 20pt;
      }

      .caption {
        font-size: 10pt;
        color: rgba(0, 0, 0, 0.5);
        padding-left: 10px;
        padding-top: 9px;
      }

      .line {
        border-bottom: 1px solid #999;
        flex: 1;
        margin-left: 10px;
        height: 19px;
      }
    }

    .box-content {
      padding-top: 10px;
    }
  }
}
