.inventory {
  font-family: "Pretendard";

  .money {
    display: flex;
    padding-bottom: 10px;
    .title {
      background: #333;
      border-radius: 20px;
      color: #ddd;
      padding: 2px 8px;
    }
    .buget {
      flex: 1;
      text-align: right;
      font-size: 14pt;
    }
  }
  .tabs {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .tab {
      cursor: pointer;
      flex: 1;
      text-align: center;
      border-bottom: 1px solid #333;

      &.selected {
        border-top: 1px solid #333;
        border-right: 1px solid #333;
        border-left: 1px solid #333;
        border-bottom: none;
      }
    }
  }

  .item-list {
    padding-top: 10px;
  }
}
