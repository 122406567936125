@keyframes fadein {
  0% {
    opacity: 0;
    transform: translate3D(0, 10px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3D(0, 0, 0);
  }
}

.item-wrap {
  border: 1px solid rgba(0, 0, 0, 0);

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .item-icon {
    border-radius: 10%;
    padding: 2px;
    display: flex;

    .left {
      flex: 1;
      img {
        margin-right: 5px;
        width: 24px;
        height: 24px;
      }
    }
    .right {
      width: 100px;
      text-align: right;
    }
  }

  .item-popup {
    pointer-events: none;
    position: fixed;
    color: #fff;
    width: 300px;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 10000;
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);

    &.visible {
      opacity: 1;
      animation: fadein 0.3s;
    }
    h5 {
      color: #fff;
    }

    .skill-component {
      padding: 5px;
      margin-bottom: 5px;
      border: 1px solid rgba(255, 255, 255, 0.2);

      .skill-img {
        width: 40px;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .skill-caption {
        padding-left: 10px;
        font-size: 11pt;

        .name {
          font-weight: bold;
          font-size: 12pt;
        }

        .options {
          margin-top: -4px;
          font-size: 9pt;
        }
      }
    }
  }

  .tool {
    position: fixed;
    width: 80px;
    background: #333;
    color: #ddd;
    padding: 8px;

    .tool-item {
      cursor: pointer;
      &:hover {
        color: #fff;
      }
    }
  }
}
