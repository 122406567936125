.cutin-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  min-width: 1680px;
  min-height: 900px;
  z-index: 300;
  overflow: hidden;

  .cutin-bg {
    position: absolute;
    top: calc(50% - 200px);
    left: -100px;
    width: calc(100% + 200px);
    height: 300px;
    transform: rotate(-5deg);
    background-color: #000;
    background-image: url("/static/img/cutin_bg.png");
    background-repeat: no-repeat;
    background-position: center center;
    animation: cut-in 0.5s;

    @keyframes cut-in {
      0% {
        height: 0;
        top: calc(50%);
      }

      100% {
        height: 300px;
        top: calc(50% - 200px);
      }
    }

    .cutin-img {
      position: absolute;
      left: 0;
      right: 0;
      top: -100px;
      width: 100%;
      height: 400px;
      clip-path: inset(0 0 0 0);
      animation: cut-in-box 0.5s;

      @keyframes cut-in-box {
        0% {
          clip-path: inset(0 0 300px 0);
        }

        100% {
          clip-path: inset(0 0 0 0);
        }
      }
    }

    img {
      width: 100%;
      height: 400px;
      object-fit: scale-down;
      object-position: center;
      animation: cut-in-img 1s;

      @keyframes cut-in-img {
        0% {
          transform: translateY(200px);
        }

        100% {
          transform: translateY(0px);
        }
      }
    }
  }
}
